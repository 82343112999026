@media screen {
    html {
        display: flex;
        justify-content: center;
        font-size: large;
    }
    body {
        margin-left: 15%;
        margin-right: 15%;
        max-width: 39em;
    }

}

@media screen and (min-width: 43em) {
    main {
        margin-left: 10%;
        margin-right: 10%;
    }
}

* {
    font-family: garabond, serif;
}
