@footer-height: 14px;
@body-top-margin: 40px;
@main-top-margin: 20px;

html, body {
    height: calc(100% - @body-top-margin);
}
body {
    margin-top: @body-top-margin;
}
main {
    min-height: calc(100% - @body-top-margin - @main-top-margin);
    margin-top: @main-top-margin;
    margin-bottom: -(@footer-height + @body-top-margin);
    /* background: #ddd; */
}
main:after {
    content: "";
    display: block;
    height: @footer-height + @body-top-margin;
}
footer {
    height: @footer-height;
    * {
        display: flex;
        align-items: center;
        justify-items: center;
    }
    background: #eee;
    font-size: @footer-height - 2px;
}
