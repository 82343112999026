a {
    text-decoration: none;
    outline: none;
    padding: 2px 1px 0;
    &:link, &:visited {
        color: #3594C6;
    }

    &:focus, &:active, &:hover {
        color: #2F566B;
        text-decoration: underline;
    }
}
