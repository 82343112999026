header {
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }
}

@nav-text-color: #203471;
@nav-bg-color: #aeaeeb;

nav {
    color: #fff;
    //background-image: linear-gradient(300deg, #aeaeeb 0%, #f4f4f4 100%);
    background-color: @nav-bg-color;
    border: 1px solid @nav-bg-color;
    padding-bottom: 0.5em;
    text-align: center;

    #navbar-toggle {
        position: absolute;
        top: 0.75em;
        right: 1em;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        font-size: 1.8em;
    }

    img {
        vertical-align: middle;
    }

    #mainlink {
        display: inline-block;
        font-size: larger;
        margin-top: 0.5em;
        margin-bottom: 0.5em;

        color: (@nav-text-color / 2); // halfway from there to black
        white-space: nowrap;

        &:hover, &:active {
            text-decoration: none;
        }
    }

    #navbar-menu {
        list-style-type: none;
        flex-direction: column;
        &:not(.active) {
            display: none;
        }

        &.active {
            display: flex;
        }

        .navbar-item {
            text-decoration: none;
            color: rgba(255, 255, 255, 0.7);
            text-align: center;
            /* margin: 15px auto; */
            a {
                display: flex;
                flex-direction: column;
                &:link, &:visited, &:hover, &:focus, &:active {
                    color: @nav-text-color;
                }
                height: 100%;
                width: 100%;
            }
        }

        .navbar-item.navbar-current {
            background: @nav-bg-color + ((#ffffff - @nav-bg-color) / 2);
        }

        .navbar-item:not(.navbar-current):hover {
            background: @nav-bg-color + ((#ffffff - @nav-bg-color)*0.62);
        }

        .navbar-submenu {
            list-style-type: none;
            display: flex;
            text-align: center;
        }
    }

    @media screen and (min-width: 43em) {

        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        height: 4em;
        align-items: center;

        #navbar-menu {
            &, &.active, &:not(.active), * {
                display: flex;
                align-items: center;
                height: 100%;
            }
            margin-left: 1.5em;
            margin-right: 1.5em;
            flex-direction: row;
            justify-content: flex-end;
            .navbar-item {
                margin: 0;
                a {
                    flex-direction: row;
                    padding-left: 0.4em;
                    padding-right: 0.4em;
                }
            }
        }
        #mainlink {
            display: flex;
            margin-left: 1em;
            margin-bottom: 0;
        }



        img {
            margin-top: 0;
        }

        #navbar-toggle {
            display: none;
        }
    }
}
