a {
  outline: none;
  padding: 2px 1px 0;
  text-decoration: none;
}

a:link, a:visited {
  color: #3594c6;
}

a:focus, a:active, a:hover {
  color: #2f566b;
  text-decoration: underline;
}

header * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

nav {
  color: #fff;
  text-align: center;
  background-color: #aeaeeb;
  border: 1px solid #aeaeeb;
  padding-bottom: .5em;
}

nav #navbar-toggle {
  cursor: pointer;
  color: #00000080;
  font-size: 1.8em;
  position: absolute;
  top: .75em;
  right: 1em;
}

nav img {
  vertical-align: middle;
}

nav #mainlink {
  color: #101a39;
  white-space: nowrap;
  margin-top: .5em;
  margin-bottom: .5em;
  font-size: larger;
  display: inline-block;
}

nav #mainlink:hover, nav #mainlink:active {
  text-decoration: none;
}

nav #navbar-menu {
  flex-direction: column;
  list-style-type: none;
}

nav #navbar-menu:not(.active) {
  display: none;
}

nav #navbar-menu.active {
  display: flex;
}

nav #navbar-menu .navbar-item {
  color: #ffffffb3;
  text-align: center;
  text-decoration: none;
}

nav #navbar-menu .navbar-item a {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
}

nav #navbar-menu .navbar-item a:link, nav #navbar-menu .navbar-item a:visited, nav #navbar-menu .navbar-item a:hover, nav #navbar-menu .navbar-item a:focus, nav #navbar-menu .navbar-item a:active {
  color: #203471;
}

nav #navbar-menu .navbar-item.navbar-current {
  background: #d7d7f5;
}

nav #navbar-menu .navbar-item:not(.navbar-current):hover {
  background: #e0e0f7;
}

nav #navbar-menu .navbar-submenu {
  text-align: center;
  list-style-type: none;
  display: flex;
}

@media screen and (min-width: 43em) {
  nav {
    height: 4em;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0;
    display: flex;
  }

  nav #navbar-menu {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }

  nav #navbar-menu, nav #navbar-menu.active, nav #navbar-menu:not(.active), nav #navbar-menu * {
    height: 100%;
    align-items: center;
    display: flex;
  }

  nav #navbar-menu .navbar-item {
    margin: 0;
  }

  nav #navbar-menu .navbar-item a {
    flex-direction: row;
    padding-left: .4em;
    padding-right: .4em;
  }

  nav #mainlink {
    margin-bottom: 0;
    margin-left: 1em;
    display: flex;
  }

  nav img {
    margin-top: 0;
  }

  nav #navbar-toggle {
    display: none;
  }
}

@media screen {
  html {
    justify-content: center;
    font-size: large;
    display: flex;
  }

  body {
    max-width: 39em;
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media screen and (min-width: 43em) {
  main {
    margin-left: 10%;
    margin-right: 10%;
  }
}

* {
  font-family: garabond, serif;
}

html, body {
  height: calc(100% - 40px);
}

body {
  margin-top: 40px;
}

main {
  min-height: calc(100% - 60px);
  margin-top: 20px;
  margin-bottom: -54px;
}

main:after {
  content: "";
  height: 54px;
  display: block;
}

footer {
  height: 14px;
  background: #eee;
  font-size: 12px;
}

footer * {
  place-items: center;
  display: flex;
}

/*# sourceMappingURL=index.27d3fe7c.css.map */
